import Node from 'ln/node/Node';
import Element from 'lb/elements/Element';
import Model from 'ln/model/Model';

export class ParagraphModel extends  Model {
    
    readonly model;
    text: string;
    glossaries: any;

    constructor(json) { 
        super(json)

        this.glossaries.forEach( (glossary) => {
            if (this.text.indexOf( glossary.term) > -1) {
                let replacement = '<span class="abbreviation">' +  glossary.term +'</span>';
                this.text = this.text.replace(glossary.term, replacement)
            }
        })
    }
}

export  class ParagraphView extends Element {

    constructor(model) {
        super( model );
    } 

    init() {
        this.node.all('.abbreviation').forEach( (node, index) => {
            
            let currentGlossar = this.data.glossaries.find( (e) => e.term === node.native.outerText )
            let tooltip = Node.fromHTML('<div class="tooltip"> '+ currentGlossar.definition +' </div>' )

            // calculate the text-width (probably better calculate the number of letters...)
            let textWidth = this.getTextWidth(currentGlossar.definition.replace(/(<([^>]+)>)/gi, "") , "300 14px futura-pt");  // close to 86
            
            node.click.add( (e) => {
                //disable tooltip-click on desktop
                if (document.documentElement.clientWidth >= 1024) {
                    return
                }

                e.toggleClass('-open')
             });
            node.append(tooltip)

            //let tooltipBounds = tooltip.native.getBoundingClientRect();

            //position it where most space is avaiable
            node.native.addEventListener('mouseenter', (e)=> {
                node.native.className = 'abbreviation';
                //check if sidebar is open
                let sidebar = document.querySelector('.content.-sidebar .sidebar-teacher');

                let space = sidebar ? (document.documentElement.clientWidth - sidebar.clientWidth) / 2 : document.documentElement.clientWidth / 2;
                
                let position = '';

                if (space < textWidth && !sidebar ) {
                    position += ' -big';
                }

                if (space < textWidth && sidebar ) {
                    position += ' -sidebar';
                }


                if (space > e.y) {
                    position += ' -bottom';
                }
                else {
                    position += ' -top';
                }
                if (space > e.x) {
                    position += ' -right'
                }
                else {
                    position += ' -left'
                }

                node.addClass(position);
            });
        })

        //close tooltip when clicked outside
        window.addEventListener('click', (e)=> {
            if (this.node.native.contains(e.target) ) {
                return
            } 

            this.node.all('.abbreviation').forEach( (node, index) => {
                node.removeClass('-open')
            });
        })
    }

    /**
     * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
     * 
     * @param {String} text The text to be rendered.
     * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
     * 
     * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
     */
    getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas = this.data.canvas || (this.data.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }
    
    getCssStyle(element, prop) {
        return window.getComputedStyle(element, null).getPropertyValue(prop);
    }
    
    getCanvasFontSize(el = document.body) {
        const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal';
        const fontSize = this.getCssStyle(el, 'font-size') || '16px';
        const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman';
        
        return `${fontWeight} ${fontSize} ${fontFamily}`;
    }
  
  



}