// import lib

import setup from 'ln/setup/setup';
import Node from 'ln/node/Node'; 
import LinkedList from'ln/linkedlist/LinkedList'; 
import Template from'ln/template/TemplateManager';
import Window from 'ln/node/Window';
import ModifiableList from 'ln/list/ModifiableList';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import Model from 'ln/model/Model';

// import project
import { mapper } from 'lb/LernBuch';
import { ioc as elements } from 'lb/elements/Chapter';

import Chapter from 'lb/elements/Chapter';

import { defaultsetup } from 'lb/setup/defaultsetup';

import { models, lernbuch, chapter, chapterNavi } from 'lb/setup/defaultsetup';


import BookModel from'lb/models/BookModel';

import ChapterModel from 'lb/models/ChapterModel';
import ElementModel from'lb/models/ElementModel';

import SingleChoice from 'lf/slides/SingleChoice/SingleChoiceModel';
import MultipleChoice from 'lf/slides/MultipleChoice/MultipleChoiceModel';
import AnswerList from 'lf/slides/AnswerList/AnswerListModel';
import { common as commonSlideModels } from  'lf/setup/models';

import Element from 'lb/elements/Element';

import QuestionContainerView from './QuestionContainerView';

import questionElementsIoC from './elements/questionElementsIoC';

import { ParagraphModel } from './Paragraph';


export interface MyChapterModel {
     title: string;
     book: { slug: string};
     elements: {};
}

elements.add( 'App\\QuestionContainer', function( elm:ElementModel ) {
    return new QuestionContainerView( elm ).render();
})



class BookContent { 
    data: BookModel;
    book: BookModel;
    currentChapter: MyChapterModel;
    chapters: MyChapterModel[];
    currentQuestion:string;
    
    constructor( data, slug  ) {
        this.data = data;

        //lernbuch mapping
        defaultsetup();

        mapper.toModel.add( 'App\\Paragraph', function ( json ) {
            return new ParagraphModel(json);
        });


        //dont use default lernbuch mapper for lernfragen, map them later
        mapper.toModel.add( 'App\\SingleChoice', function ( json ) {
            return json;
        });
        mapper.toModel.alias('App\\MultipleChoice', 'App\\SingleChoice');
        mapper.toModel.alias('App\\ChoiceAnswer', 'App\\SingleChoice');
        mapper.toModel.alias('App\\DropDown', 'App\\SingleChoice');

         //add sort index to chapter
        mapper.toModel.add( 'App\\Chapter', function( json:any ){
            var chapter = new ChapterModel( json ) ;
            chapter.elements = new List<ChapterModel>( json.elements );
            chapter.set( 'hasSolution',  this.hasSolution(chapter));
            chapter.elements.all().forEach( function( element, index ){
                element.set( 'chapter', chapter );
                element.set( 'sort', index );
            }, this );
        
            return chapter;
        }.bind(this));
        mapper.toModel.add( 'App\\Book', function( json:any ){
            var book = new BookModel( json ) ;
            book.chapters.forEach( function( chapter, index ){
                chapter.set( 'parent', book );
            }, this );
        
            return book;
        });

        this.book = mapper.model( data ) as BookModel;
        
        this.currentChapter  = ( slug != '' ) ? this.book.chapterOf(slug) : this.book.chapters[0];

        Node.js('site-title').html = this.currentChapter.title;
        this.showBook();

        this.currentQuestion = this.currentChapter.elements.items[0].uid;
        this.showTeacherNavigation();
    }

    hasSolution(chapter) {
        let solution;
        chapter.elements.all().forEach( (element)=> {
            element.questions.forEach( (q)=> {
                if (q.solution && q.solution.length > 0) {
                    solution = true;
                };
            });
        });
        return solution === true ? true : false;
    }


    showBook () {
        
        new Chapter( this.currentChapter, this ).render(  Node.one( '#lernbuch' ) ) as Chapter;
    }

    showTeacherNavigation() {

            var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
            if ( viewportWidth > 1024 ){
                Node.one('.button-teacher').toggleClass('-close');
                Node.one('.sidebar-teacher').toggleClass('-close');
                Node.one('.page-layout .content').toggleClass('-sidebar');

            }

            Node.one('.button-teacher').click.add( ( node )=>{
                node.toggleClass('-close');
                Node.one('.sidebar-teacher').toggleClass('-close');
                Node.one('.page-layout .content').toggleClass('-sidebar');
		});
        
        

		Node.all('.container-teacher > .title').forEach( ( node, index ) => {


            node.click.add( ( title )=> {
                if (this.currentChapter.get('is_teacher')) {
                    title.parent().one('.content').toggleClass('-close');
                    title.one('.iconcontainer').toggleClass('-inverted');
                }

                //scroll to chapter-element
                if ( node.parent().one('.-chapter') ) {
                    var pos = Node.one('#' + node.getAttribute( 'data-uid' )).bounds();
                    var position = 200;
                    
                    if (Node.one('.page-header').hasClass('-collabse') ){
                        var position = 75;
                    }

                    window.scroll(0, pos.top - position) ;
                }
                
            });
		});

    };

};
export default BookContent;