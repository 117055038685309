import Element from 'lb/elements/Element';
import ElementModel from 'lb/models/ElementModel';
import IoC from 'ln/ioc/IoC';
import {ImageView} from '../ImageView';

import { CarouselView } from '../Carousel';
import { ParagraphView } from '../Paragraph';

import Quiz from '../Quiz';

export var questionElementsIoC = new IoC();


questionElementsIoC.add( 'App\\MultipleChoice', function( mc ) {
	var slide = new Quiz( [ mc ] ).render();
	return slide;
});

questionElementsIoC.add( 'App\\SingleChoice', function( sc ) {
	var slide = new Quiz( [ sc ] ).render();
	return slide;
});

questionElementsIoC.add( 'SingleChoice', function( sc ) {
    var slide = new Quiz( [ sc ] ).render();
	return slide;
});


questionElementsIoC.add( 'App\\DropDown', function( dd ) {
	var slide = new Quiz( [ dd ] ).render();
	return slide;
});

questionElementsIoC.add( 'App\\Image', function( elm:ElementModel ) {
    return new ImageView( elm ).render();
})

questionElementsIoC.add( 'App\\Carousel', function( elm:ElementModel ) {
    return new CarouselView( elm ).render();
})


// questionElementsIoC.add( 'App\\Video', function ( model:ElementModel ) {
// 	     return new Element( model ).render() as Element;
//  });


questionElementsIoC.add( 'App\\Paragraph', function ( model:ElementModel|any ) {
	return new ParagraphView( model ).render() as Element;
});

// questionElementsIoC.add( 'App\\Image', function ( model:ElementModel ) {
// 	return new Element( model ).render() as Element;
// });

// questionElementsIoC.add( 'App\\IFrame', function ( model:ElementModel ) {
// 	return new Element( model ).render() as Element;
// });

// default (paragraph-element, title, iframe, video...)
questionElementsIoC.add( 'default', function ( model:ElementModel ) {
    return new Element( model ).render() as Element;
});

